/*
Namespace: “bn-card"
Description: "Bordered container with shadow, currently used to display quotes and plain content"
Variants:
--rounded: "Adds rounded corners"
*/

.bn-card {
  background-color: white;
  border: var(--box-border);
  box-shadow: var(--box-shadow);
  display: flex;
  position: relative;
  width: 100%;

  &:not(.bn-card--row) {
    flex-direction: column;
  }

  &--hover-effect {
    @media (hover) {
      box-shadow: var(--box-shadow-hover);
      transition: all 0.2s ease-out;

      &:hover {
        transform: translateY(-0.125rem) scale(1.01);

        .bn-card__header {
          color: var(--bn-orange);
        }
      }
    }
  }

  &--no-border {
    border: none;
    box-shadow: none;
  }

  &--rounded {
    border-radius: var(--box-border-radius);
    overflow: hidden;
  }

  &--rounded-s {
    border-radius: var(--box-border-radius-s);
    overflow: hidden;
  }

  &--contain-illustration {
    .bn-card__img-wrapper {
      padding: var(--spacing-s) var(--spacing-s) 0 var(--spacing-s);
    }
  }

  &--bg-cover {
    .bn-card__img {
      background-size: cover;
      height: 12.5rem;
    }
  }

  &__selected-icon {
    filter: var(--bn-white-filter);
    opacity: 0;
    position: absolute;
    right: var(--spacing);
    top: var(--spacing);
    transform: translateX(1rem) scale(0.8);
    transition: all 0.3s ease-in-out;
  }

  &--selected {
    &.bn-card {
      &--selected-success {
        .bn-card {
          background-color: var(--bn-success-bg);
          transition: all 0.2s ease-out;

          &__img-wrapper {
            background-color: rgb(var(--bn-success-rgb), 0.2) !important;
          }
        }

        .bn-pill {
          background-color: var(--bn-success-bg-active) !important;
        }
      }

      &--selected-danger {
        .bn-card {
          background-color: var(--bn-danger-bg);
          transition: all 0.2s ease-out;

          &__img-wrapper {
            background-color: rgb(var(--bn-danger-rgb), 0.9) !important;
          }
        }

        .bn-pill {
          background-color: var(--bn-danger-bg-active) !important;
        }
      }

      &--selected-success,
      &--selected-danger {
        .bn-card {
          &__header,
          &__body,
          &__label {
            color: white;
          }

          &__selected-icon {
            opacity: 1;
            transform: translateX(0) scale(1);
          }
        }

        .bk-btn {
          border: 1px solid white;
        }

        .bk-btn img {
          filter: var(--bn-white-filter);
        }

        .bk-btn span {
          color: white !important;
        }
      }
    }
  }

  &__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 7.5rem;
    width: 100%;

    &--cover {
      object-fit: cover;
    }
  }

  &__body {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: initial;
    justify-content: space-between;
    padding: var(--spacing-l);
    text-align: left;

    p {
      margin-bottom: 0;
    }

    > :last-child {
      padding-bottom: 0;
    }

    @media (--screen-md) {
      flex-direction: column;
    }
  }

  &__bang {
    border-bottom: solid var(--bang-height) var(--bn-orange);
    border-radius: 0.5rem;
    margin-bottom: var(--spacing);
    width: 2rem;
  }

  &__header {
    color: var(--grey-600);
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
    padding-bottom: var(--spacing);
    text-align: left;

    &--centered {
      text-align: center;
    }
  }

  &__label,
  &__sub-header,
  &__date {
    color: var(--grey-600);
    display: block;
    font-size: var(--font-sm);
    margin-bottom: var(--spacing-s);
    width: 100%;
  }

  &__sub-header,
  &__date {
    justify-content: center;
  }

  &__date {
    display: flex;
    margin-bottom: var(--spacing);

    &--danger {
      align-items: center;
      color: var(--bn-danger-bg);
      display: flex;
      gap: var(--spacing-xxxs);

      .bn-card__date-icon {
        filter: var(--bn-danger-filter);
      }
    }
  }

  &__text {
    margin-bottom: var(--spacing-m);
  }

  &__text-strong {
    font-weight: var(--font-semi-bold);
  }

  &__btn-wrapper {
    align-items: center;
    display: flex;
    flex: none;
    justify-content: center;
  }

  &__link {
    font-size: var(--font-sm);
    font-weight: var(--font-bold);
    margin-top: var(--spacing);
  }
}
